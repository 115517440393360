import React, { useEffect, useState, useRef } from "react";
import { loadModules } from "esri-loader";

const locations = [
  { Plats: "Stockholm", X: 673966.677, Y: 6580800.874 },
  { Plats: "Malmö", X: 373972.129, Y: 6164341.497 },
  { Plats: "Uppsala", X: 648191.825, Y: 6638640.255 },
  { Plats: "Åland", X: 774487, Y: 6678752 },
  { Plats: "Östersund", X: 481765.03, Y: 7004644.066 },
  { Plats: "Kumla", X: 508023.175, Y: 6554144.185 },
  { Plats: "Varberg", X: 333403.307, Y: 6332922.066 },
  { Plats: "Lidköping", X: 392981.168, Y: 6486348.056 },
  { Plats: "Alingsås", X: 353877.972, Y: 6423261.169 },
  { Plats: "Båstad", X: 368614.536, Y: 6255438.465 },
  { Plats: "Katrineholm", X: 569429, Y: 6540359 },
  { Plats: "Degerfors", X: 467506.199, Y: 6566664.717 },
];

const MapComponent = () => {
  const [view, setView] = useState(null);
  const [isListVisible, setIsListVisible] = useState(false);
  const [tooltip, setTooltip] = useState({
    visible: false,
    text: "",
    x: 0,
    y: 0,
  });
  const [filterActive, setFilterActive] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const measurementWidgetRef = useRef(null);
  const areaMeasurementWidgetRef = useRef(null);
  const measurementModuleRef = useRef(null);
  const areaMeasurementModuleRef = useRef(null);

  useEffect(() => {
    loadModules(
      [
        "esri/config",
        "esri/views/SceneView",
        "esri/WebScene",
        "esri/geometry/SpatialReference",
        "esri/geometry/Extent",
        "esri/geometry/Point",
        "esri/geometry/projection",
        "esri/layers/GraphicsLayer",
        "esri/Graphic",
        "esri/layers/FeatureLayer",
        "esri/renderers/SimpleRenderer",
        "esri/layers/IntegratedMeshLayer",
        "esri/widgets/BasemapGallery",
        "esri/widgets/Expand",
        "esri/widgets/LayerList",
        "esri/widgets/Home",
        "esri/widgets/Legend",
        "esri/widgets/DirectLineMeasurement3D",
        "esri/widgets/AreaMeasurement3D",
        "esri/layers/support/FeatureEffect",
      ],
      { css: true }
    ).then(
      ([
        esriConfig,
        SceneView,
        WebScene,
        SpatialReference,
        Extent,
        Point,
        projection,
        GraphicsLayer,
        Graphic,
        FeatureLayer,
        SimpleRenderer,
        IntegratedMeshLayer,
        BasemapGallery,
        Expand,
        LayerList,
        Home,
        Legend,
        DirectLineMeasurement3D,
        AreaMeasurement3D,
        FeatureEffect,
      ]) => {
        esriConfig.geometryServiceUrl =
          "https://utility.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer";

        measurementModuleRef.current = DirectLineMeasurement3D;
        areaMeasurementModuleRef.current = AreaMeasurement3D;

        const sweref99 = new SpatialReference({ wkid: 3006 });
        const wgs84 = new SpatialReference({ wkid: 4326 });

        const sceneView = new SceneView({
          container: "viewDiv",
          map: new WebScene({
            portalItem: { id: "a62692d5a3094d0582fed656736bca62" },
            ground: "world-elevation",
          }),
          environment: {
            atmosphere: { quality: "high" },
            starsEnabled: true,
            atmosphereEnabled: true,
            lighting: {
              type: "sun",
              date: new Date("Mars 15, 2025 11:00:00 UTC"),
              directShadowsEnabled: true,
            },
            weather: {
              type: "cloudy",
              cloudCover: 0.2,
            },
          },
        });

        sceneView.when(() => {
          const xs = locations.map((loc) => loc.X);
          const ys = locations.map((loc) => loc.Y);
          const minX = Math.min(...xs);
          const minY = Math.min(...ys);
          const maxX = Math.max(...xs);
          const maxY = Math.max(...ys);
          const extent = new Extent({
            xmin: minX,
            ymin: minY,
            xmax: maxX,
            ymax: maxY,
            spatialReference: sweref99,
          });
          const projectedExtent = projection.project(extent, wgs84);

          sceneView.goTo({
            target: projectedExtent.expand(1.1),
            tilt: 50,
          });

          const homeWidget = new Home({
            view: sceneView,
          });
          const layerList = new LayerList({
            view: sceneView,
          });
          const layerListExpand = new Expand({
            view: sceneView,
            content: layerList,
            expandIconClass: "esri-icon-layers",
            expanded: false,
          });
          const basemapGallery = new BasemapGallery({
            view: sceneView,
          });
          const basemapExpand = new Expand({
            view: sceneView,
            content: basemapGallery,
            expandIconClass: "esri-icon-basemap",
            expanded: false,
          });
          const legendWidget = new Legend({
            view: sceneView,
          });
          const legendExpand = new Expand({
            view: sceneView,
            content: legendWidget,
            expandIconClass: "esri-icon-legend",
            expanded: false,
          });
          measurementWidgetRef.current = new DirectLineMeasurement3D({
            view: sceneView,
          });
          areaMeasurementWidgetRef.current = new AreaMeasurement3D({
            view: sceneView,
          });
          const measurementExpand = new Expand({
            view: sceneView,
            content: measurementWidgetRef.current,
            expandIconClass: "esri-icon-measure-line",
          });
          const areaMeasurementExpand = new Expand({
            view: sceneView,
            content: areaMeasurementWidgetRef.current,
            expandIconClass: "esri-icon-measure-area",
          });

          sceneView.ui.add(homeWidget, "top-left");
          sceneView.ui.add(basemapExpand, "top-left");
          sceneView.ui.add(layerListExpand, "top-left");
          sceneView.ui.add(legendExpand, "top-left");
          sceneView.ui.add(measurementExpand, "top-left");
          sceneView.ui.add(areaMeasurementExpand, "top-left");

          const buildingsLayer = sceneView.map.layers.find(
            (layer) => layer.title === "3D Buildings"
          );
          buildingsLayer.opacity = 0.6;

          let originalOpacity = null;

          const barLayer = sceneView.map.layers.find(
            (layer) => layer.title === "Medarbetare"
          );

          console.log(barLayer);

          barLayer.outFields = ["*"];

          sceneView.on("pointer-move", (event) => {
            sceneView.hitTest(event).then((response) => {
              if (response.results.length > 0) {
                const graphic = response.results[0].graphic;

                if (graphic && graphic.layer === barLayer) {
                  if (originalOpacity === null) {
                    originalOpacity = barLayer.opacity;
                  }

                  graphic.layer.opacity = 1;

                  setTooltip({
                    visible: true,
                    text: `${graphic.attributes.Plats}: ${graphic.attributes.Antal} st\n`,
                    x: event.x + 10,
                    y: event.y + 10,
                  });

                  return;
                }
              }

              if (originalOpacity !== null) {
                barLayer.opacity = originalOpacity;
                originalOpacity = null;
              }

              setTooltip({ visible: false, text: "", x: 0, y: 0 });
            });
          });
          sceneView.on("click", (event) => {
            sceneView.hitTest(event).then((response) => {
              if (response.results.length > 0) {
                const graphic = response.results[0].graphic;
                if (graphic && graphic.layer === barLayer) {
                  sceneView.goTo(graphic, { duration: 1000 });
                }
              }
            });
          });
        });

        sceneView.watch("scale", (newScale) => {
          const barLayer = sceneView.map.layers.find(
            (layer) => layer.title === "Medarbetare"
          );

          if (
            !barLayer ||
            !barLayer.renderer ||
            !barLayer.renderer.visualVariables
          ) {
            console.warn("Layer eller renderer saknas!");
            return;
          }

          const maxVisibleScale = 50000;

          if (newScale < maxVisibleScale) {
            barLayer.visible = false;
          } else {
            barLayer.visible = true;
          }

          const rendererClone = barLayer.renderer.clone();
          const visualVariables = [...rendererClone.visualVariables];

          const heightVariable = visualVariables.find(
            (vv) => vv.axis === "height"
          );
          if (!heightVariable) {
            console.warn("Ingen height-visualVariable hittades!");
            return;
          }

          const worldScale = 500000;
          const minSize = 5000;
          const maxSize = 10000;
          let scaleFactor = Math.max(0.5, Math.min(10, newScale / worldScale));

          heightVariable.maxSize = Math.max(minSize, maxSize * scaleFactor);
          heightVariable.minSize = Math.max(minSize / 2, minSize * scaleFactor);

          rendererClone.visualVariables = visualVariables;
          barLayer.renderer = rendererClone;

          const minOpacity = 0.3;
          const maxOpacity = 1;
          const minScale = 5000;
          const maxScale = 100000;

          let opacityFactor =
            (heightVariable.maxSize - minScale) / (maxScale - minScale);
          opacityFactor = Math.min(1, Math.max(0, opacityFactor));

          let newOpacity =
            minOpacity + (maxOpacity - minOpacity) * opacityFactor;

          if (barLayer.opacity !== newOpacity) {
            barLayer.opacity = newOpacity;
          }
        });

        projection.load().then(() => {
          const newView = {
            sceneView,
            zoomTo: (x, y) => {
              const point = new Point({ x, y, spatialReference: sweref99 });
              const projectedPoint = projection.project(point, wgs84);
              sceneView.goTo({
                center: [projectedPoint.x, projectedPoint.y],
                zoom: 15,
                tilt: 70,
              });
            },
            showAll: () => {
              const xs = locations.map((loc) => loc.X);
              const ys = locations.map((loc) => loc.Y);
              const minX = Math.min(...xs);
              const minY = Math.min(...ys);
              const maxX = Math.max(...xs);
              const maxY = Math.max(...ys);

              const extent = new Extent({
                xmin: minX,
                ymin: minY,
                xmax: maxX,
                ymax: maxY,
                spatialReference: sweref99,
              });

              const projectedExtent = projection.project(extent, wgs84);
              sceneView.goTo({
                target: projectedExtent.expand(1.1),
                tilt: 50,
              });
            },
          };

          setView(newView);
        });

        return () => {
          if (sceneView) {
            sceneView.destroy();
          }
        };
      }
    );
  }, []);

  useEffect(() => {
    if (view) {
      view.showAll();
    }
  }, [view]);

  const toggleFilter = (filterValue) => {
    setFilterActive((prevFilters) => {
      const updatedFilters = prevFilters.includes(filterValue)
        ? prevFilters.filter((f) => f !== filterValue)
        : [...prevFilters, filterValue];

      applyFilter(updatedFilters);
      return updatedFilters;
    });
  };

  const applyFilter = (filters) => {
    if (view && view.sceneView) {
      const sceneView = view.sceneView;

      if (sceneView.map) {
        const layer = sceneView.map.layers.find(
          (layer) => layer.title === "Medarbetare"
        );

        if (layer) {
          if (filters.length > 0) {
            let conditions = [];

            if (filters.includes("TD Spatial")) {
              conditions.push(`"Avdelning" LIKE '%Spatial%'`);
            }
            if (filters.includes("TD Asset")) {
              conditions.push(`"Avdelning" LIKE '%Asset%'`);
            }

            layer.definitionExpression = conditions.join(" OR ");
          } else {
            layer.definitionExpression = "";
          }
        } else {
          console.warn("⚠️ Lagret 'Medarbetare' kunde inte hittas.");
        }
      }
    }
  };

  const clearMeasurements = () => {
    measurementWidgetRef.current.destroy();
    areaMeasurementWidgetRef.current.destroy();
  };

  return (
    <div>
      {/* Web Scene */}
      <div
        id="viewDiv"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></div>

      {/* Refresh knapp */}
      <div className="clean-widget" title="Rensa karta">
        <div className="clean-widget-toggle" onClick={clearMeasurements}>
          <div className="clean-widget-icon">🔄</div>
        </div>
      </div>

      {/* Filterknappar Widget */}
      <div className="filter-widget" title="Avdelningar">
        <div
          className="filter-widget-toggle"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="filter-widget-icon">=</div>
        </div>

        {isExpanded && (
          <div className="filter-widget-filters">
            <div
              className={`filter-widget-content ${
                filterActive.includes("TD Spatial") ? "active" : ""
              }`}
              onClick={() => toggleFilter("TD Spatial")}
            >
              TD Spatial
            </div>

            <div
              className={`filter-widget-content ${
                filterActive.includes("TD Asset") ? "active" : ""
              }`}
              onClick={() => toggleFilter("TD Asset")}
            >
              TD Asset
            </div>
          </div>
        )}
      </div>

      {/* Platslista Widget */}
      <div
        className="locations-widget"
        onClick={() => setIsListVisible(!isListVisible)}
      >
        <h4 className="locations-widget-title">Här finns vi ⤵️</h4>

        {isListVisible && (
          <ul className="locations-widget-list">
            <div
              className="locations-widget-content"
              onClick={(e) => {
                e.stopPropagation();
                view && view.showAll();
              }}
            >
              Alla
            </div>
            {locations.map((location, index) => (
              <div key={index} className="locations-widget-content">
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    view && view.zoomTo(location.X, location.Y);
                  }}
                >
                  {location.Plats}
                </li>
              </div>
            ))}
          </ul>
        )}
      </div>

      {/* Tooltip vid hovring */}
      {tooltip.visible && (
        <div
          style={{
            position: "absolute",
            top: tooltip.y,
            left: tooltip.x,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "14px",
            pointerEvents: "none",
            whiteSpace: "pre-line",
          }}
        >
          {tooltip.text}
        </div>
      )}
    </div>
  );
};

export default MapComponent;
