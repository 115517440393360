export const appTexts = {
  title: "Total Digital - experter på digitala lösningar",
};

export const heroTexts = {
  erbjuderTitle: "Vad vi erbjuder",
  erbjuderText1:
    "Total Digital erbjuder tjänster inom digitaliserat underhåll och systemstöd. Vi hjälper er med att designa en IT-plattform för ett strömlinjeformat och effektivt underhållsarbete. Vi har lång erfarenhet av konfiguration, utveckling och förvaltning av Maximo och vi erbjuder lösningar inom informationsmodellering och digitala anläggningsmodeller. Anläggningsmodellen och anläggningsdatabasen utgör ryggraden inom digitaliserat underhåll och vi erbjuder integrerade kartapplikationer för att förbättra upplevelsen och ge användare möjlighet att besöka anläggningen virtuellt.",
  erbjuderText2:
    "Med hög kvalitet och god vägledning skapar vi mervärde för våra kunder. Vi erbjuder heltäckande expertis inom IBM Maximo och ArcGIS samt kringliggande tjänster. Vår expertis och våra tjänster genomsyras av lång erfarenhet samt smidig och flexibel service. Total Digital arbetar sedan många år intensivt med att stötta anläggningsägare med idéer i strävan efter mer effektivt och träffsäkert underhåll genom implementering av digital teknologi och artificiell intelligens.",
  buttonText: "Läs mer",
};

export const headerTexts = {
  logoAlt: "Logo Total Digital",
  menuItems: {
    main: [
      { id: "business", label: "Affärsområden" },
      { id: "products", label: "Produkter" },
      { id: "services", label: "Tjänster" },
      { id: "partners", label: "Partners" },
      { id: "about", label: "Om oss" },
      { id: "contact", label: "Kontakt" },
    ],
    subMenu: [
      { link: "/maximo", label: "Maximo tjänster" },
      { link: "/geodata", label: "Geografiska tjänster" },
      { link: "/tdassetcare", label: "TD Asset Care" },
      { link: "/tdtest", label: "TD Test" },
    ],
    tdtest: [
      { id: "vadartdtest", label: "Vad är TD Test?" },
      { id: "funktioner", label: "Funktioner" },
      { id: "kontakt", label: "Kontakt" },
    ],
  },
};

export const businessTexts = {
  businessSubMenu: [
    { link: "/infra", label: "Infrastruktur" },
    { link: "/industry", label: "Industri" },
    { link: "/facility", label: "Fastighet" },
    { link: "/fleet", label: "FleetManagement" },
  ],
  businessDesc: [
    {
      label: "Infrastruktur",
      desc1: "Vi erbjuder omfattande lösningar för infrastruktur.",
      desc2: `
        <ul>
          <li>Väg</li>
          <li>Järnväg</li>
          <li>Elkraft</li>
          <li>Vatten/Avlopp</li>
        </ul>
      `,
    },
    {
      label: "Industri",
      desc1: "Innovativa lösningar för industriell effektivitet.",
      desc2: `
        <ul>
          <li>Cellulosaindustri</li>
          <li>Pappersbruk</li>
          <li>Gruvindustrin</li>
        </ul>
      `,
    },
    {
      label: "Fastighet",
      desc1: "Moderna lösningar för drift och underhåll av fastigheter.",
      desc2: `
        <ul>
          <li>Facility Management</li>
          <li>Indoor GIS</li>
        </ul>
      `,
    },
    {
      label: "FleetManagement",
      desc1: "Effektiv hantering av fordonsverksamhet.",
      desc2: `
        <ul>
          <li>Fordonsunderhåll</li>
          <li>Monitorering och optimering</li>
        </ul>
      `,
    },
  ],
};

export const infrastructure = {
  header: "Infrastruktur",
  services: [
    {
      title: "Väg",
      description:
        "Vi erbjuder lösningar för säker, hållbar och effektiv väghantering, från underhåll till smarta trafiksystem.",
    },
    {
      title: "Järnväg",
      description:
        "Vi hjälper till att optimera och modernisera järnvägsinfrastrukturen för ökad kapacitet och säkerhet.",
    },
    {
      title: "Elkraft",
      description:
        "Våra tjänster inom elkraft inkluderar driftövervakning och underhåll av elnät och kraftstationer.",
    },
    {
      title: "Vatten/Avlopp",
      description:
        "Vi erbjuder hållbara lösningar för planering, underhåll och optimering av vatten- och avloppssystem.",
    },
  ],
  desc1:
    "Inom infrastruktur erbjuder vi en rad olika tjänster som är designade för att möta era specifika behov. Vårt fokus är att leverera kostnadseffektiva och hållbara lösningar.",
  desc2:
    "Vi stödjer er genom hela processen, från planering och design till implementering och underhåll.",
};

export const industry = {
  header: "Industri",
  services: [
    {
      title: "Cellulosa",
      description:
        "Vi tillhandahåller lösningar som förbättrar effektiviteten och hållbarheten i cellulosaproduktion.",
    },
    {
      title: "Pappersbruk",
      description:
        "Våra tjänster hjälper pappersbruk att optimera produktionslinjer och minimera driftstopp.",
    },
    {
      title: "Gruvindustri",
      description:
        "Vi stödjer gruvindustrin med smarta lösningar för tillgångshantering och processoptimering.",
    },
  ],
  desc1:
    "Vi erbjuder avancerade lösningar för att förbättra produktivitet, minska driftstopp och optimera processer inom industrisektorn.",
  desc2:
    "Vårt team stödjer er i hela värdekedjan, från strategi och planering till implementering och drift.",
};

export const facility = {
  header: "Fastighet",
  services: [
    {
      title: "Facility management",
      description:
        "Vi hjälper er att effektivisera drift och underhåll av era fastigheter för bättre kostnadskontroll.",
    },
    {
      title: "Indoor GIS",
      description:
        "Våra lösningar för Indoor GIS ger er en bättre överblick och hantering av fastighetens infrastruktur.",
    },
  ],
  desc1:
    "Med vår expertis inom fastighetshantering kan vi hjälpa er att effektivisera drift och minska kostnader.",
  desc2:
    "Vi levererar skräddarsydda lösningar för att förbättra livslängden på era byggnader och öka användarupplevelsen.",
};

export const fleetmanagement = {
  header: "Fleet management",
  services: [
    {
      title: "Fordonsunderhåll",
      description:
        "Våra lösningar hjälper er att hålla fordon i toppskick med planerat underhåll och optimerade processer.",
    },
    {
      title: "Monitorering",
      description:
        "Vi erbjuder avancerade monitoreringssystem för realtidsöverblick av er fordonsverksamhet.",
    },
  ],
  desc1:
    "Vi erbjuder lösningar som hjälper er att hantera och optimera fordonsverksamhet, från bränsleeffektivitet till spårning.",
  desc2:
    "Vårt mål är att förbättra tillförlitligheten och minska kostnaderna samtidigt som produktiviteten maximeras.",
};

export const serviceTexts = {
  header: "Tjänster",
  serviceDescriptions: [
    {
      title: "Implementation av IT-stöd för tillgångsförvaltning",
      description:
        "Vi erbjuder expertis inom alla delar av systemen IBM Maximo och Esri ArcGIS för effektiv tillgångsförvaltning.",
    },
    {
      title: "Applikationsutveckling och konfiguration",
      description:
        "Vi hjälper er att säkerställa användandet av IBM Maximo och Esri ArcGIS tillsammans med integrerade system för bästa resultat.",
    },
    {
      title: "Sensorteknik",
      description:
        "Med vår kunskap och erfarenhet inom sensorteknik hjälper vi er att utveckla och förvalta anläggningssystemet på ett effektivare sätt.",
    },
    {
      title: "Licenser och drift",
      description:
        "Vi tar hand om alla licensprocesser och förenklar hanteringen av licenser för både IBM Maximo och Esri ArcGIS.",
    },
    {
      title:
        "Projektledning och rådgivning inom anläggningsinformation, digitalisering och AI",
      description:
        "Vi stöttar er i olika typer av projekt, från förändringsprojekt till migreringsprojekt, med IBM Maximo och Esri ArcGIS.",
    },
    {
      title: "Utveckling och analys inom geodataområdet",
      description:
        "Vi erbjuder omfattande tjänster inom utveckling och analys av geodata för att förbättra era arbetsflöden och beslutsprocesser.",
    },
    {
      title: "Tjänstebaserad och produktifierade leveranser",
      description:
        "Vi erbjuder teknisk expertis för att övervaka och hantera hälsa och prestanda inom både IBM Maximo och Esri ArcGIS.",
    },
    {
      title: "Utbildning",
      description:
        "Vi erbjuder kundanpassad utbildning inom både IBM Maximo och Esri ArcGIS för att säkerställa att ni får ut det mesta av era system.",
    },
  ],
};

export const partnerTexts = {
  header: "Partners",
  introText:
    "Vi anser att ett kraftfullt partnernätverk är nyckeln till framgång. Vi utnyttjar ofta den hävkraft vi har tillgång till genom våra kraftfulla partnerskap och vårt stora nätverk för att maximera slutresultatet för våra kunder.",
  partners: [
    {
      name: "IBM",
      description:
        "IBM är vår huvudpartner som äger och vidareutvecklar underhållssystemet IBM Maximo.",
      link: "http://www.ibm.com/",
      alt: "IBM logo in blue",
    },
    {
      name: "Esri",
      description:
        "Esri är den globalt marknadsledande utvecklaren av GIS-teknologi. Vi har ett nära partner-samarbete och använder ESRI plattformen ArcGIS i flera av de tjänster vi erbjuder.",
      link: "https://www.esri.com/",
      alt: "ESRI logo in blue",
    },
    {
      name: "Pedab",
      description: "Pedab är vår distributör av IBM-licenser",
      link: "https://www.pedab.se/",
      alt: "Pedab",
    },
    {
      name: "BPD Zenith",
      description:
        "Vi är BPD Zeniths nordiska partner och kan genom dem erbjuda en samling paketerade lösningar och tjänster kring Maximo.",
      link: "https://www.bpdzenith.com/",
      alt: "BPD Zenith logo",
    },
    {
      name: "Maximo användarförening",
      description:
        "Total Digital är en aktiv medlem i Sveriges största användarforum för Maximoanvändare.",
      link: "https://www.maxanv.se/",
      alt: "Maximo användarförening",
    },
  ],
};

export const aboutTexts = {
  header: "Om Total Digital",
  content: [
    "Vårt motto är ”Lilla företagets smidighet med det stora företagets styrka”. Det strävar vi ständigt efter att uppnå. Vårt förhållandevis lilla företag opererar smidigt och flexibelt med korta beslutsvägar och platt organisation, samtidigt som vi utnyttjar den breda och djupa expertis vi besitter.",
    "På Total Digital har vi omfattande expertis inom IBM Maximo och ArcGIS. Våra team består av specialister med djupgående kunskaper inom dessa områden, där vi både har en stark bakgrund inom IBM och erfarenheter av Maximo, och även en stark kompetens inom Geoinformatik och ArcGIS. Med i genomsnitt över 20 års erfarenhet är våra medarbetare experter på att leverera lösningar som är skräddarsydda efter specifika behov.",
    "Vi är unika i vår förmåga att sammanföra Maximo och ArcGIS för att skapa integrerade lösningar som ökar både funktionalitet och effektivitet för våra kunder. Denna förmåga att kombinera expertiser gör oss till en värdefull partner för de som söker innovativa och välintegrerade tekniklösningar.",
    "Vi tror på långsiktighet. Givetvis ur ett samarbetsperspektiv, men framför allt ur ett kvalitetsperspektiv. För att nå dit krävs hög service och expertis samt tillit och tillgänglighet – något vi på Total Digital har svart bälte i.",
  ],
};

export const contactFormTexts = {
  header: "Kontakt",
  description: "Vill ni veta mer om oss? Kontakta oss i dag!",
  fields: {
    fullname: "Namn",
    email: "E-post*",
    mobile: "Telefon",
    company: "Företag",
    message: "Meddelande",
    gdprConsent:
      "Jag tillåter Total Digital att lagra och behandla mina personuppgifter och ta emot kommunikation via e-post.",
  },
  buttonText: "Kontakta oss",
};

export const footerTexts = {
  header: "Kontakta oss",
  address: {
    name: "Total Digital AB",
    street: "Fabriksgatan 4",
    city: "531 60 Lidköping",
  },
  contacts: [
    {
      type: "E-post",
      value: "info@totaldigital.se",
      link: "mailto:info@totaldigital.se",
    },
    {
      type: "Telefon",
      value: "010 - 10 100 70",
      link: "tel:0101010070",
    },
  ],
  followUs: {
    header: "Följ oss",
    linkedin: {
      link: "https://www.linkedin.com/company/total-digital-it-solutions/about/",
      alt: "linkedin",
    },
  },
  shortcuts: {
    header: "Genvägar",
    links: [
      { name: "Affärsområden", link: "#business" },
      { name: "Produkter", link: "#products" },
      { name: "Tjänster", link: "#services" },
      { name: "Partners", link: "#partners" },
      { name: "Om oss", link: "#about" },
      { name: "Kontakt", link: "#footer" },
    ],
  },
};

export const maximoServiceTexts = {
  header: "Våra tjänster inom Maximo",
  services: [
    {
      title: "IBM Maximo",
      description:
        "Vi erbjuder expertis inom alla delar av underhållssystemet IBM Maximo. Några av våra expertområden är bland annat utveckling, konfiguration/anpassning, installation, design, integration och rapportutveckling.",
    },
    {
      title: "Licenser och licenshantering",
      description:
        "Vi tar hand om alla licensprocesser och förenklar hanteringen av licenser. Vi tar ansvar och hjälper er genom hela kedjan av licenshantering.",
    },
    {
      title: "Förvaltning och support",
      description:
        "Vi hjälper er att säkerställa användandet av IBM Maximo tillsammans med integrerade system samt specifika frågor rörande ert underhållssystem.",
    },
    {
      title: "Drift",
      description:
        "Vi tar ansvar för drifthanteringen av er molntjänst, oavsett moln eller användning av flera molntjänster i en och samma infrastruktur. Vi sköter tjänsten i IBM Cloud och ansvarar även för supportärenden.",
    },
    {
      title: "Utbildning",
      description:
        "Vi erbjuder kundanpassad utbildning inom IBM Maximo-applikation. Oavsett om det handlar om grundläggande kunskaper eller specifika och avancerade områden inom IBM Maximo. Vi kan hjälpa till med att kartlägga och anpassa verksamhetens processer till systemet.",
    },
    {
      title: "Projekt",
      description:
        "Vi stöttar er i flera former av projekt. Allt från förändringsprojekt till migreringsprojekt. Oavsett om det handlar om att byta system eller integrationsplattform hjälper vi er anpassa det till IBM Maximo.",
    },
    {
      title: "Teknisk expertis",
      description:
        "Vi erbjuder teknisk expertis för att kontrollera och hantera aktuell hälsa och prestanda inom ert IBM Maximo.",
    },
    {
      title: "Automattester",
      description:
        "Med vår produkt TD Test hjälper vi er att kvalitetssäkra ert Maximo. TD Test innehåller avancerad funktionalitet för att enkelt skapa avancerade, snabba och stabila automattester för era processer och anpassningar.",
    },
  ],
};

export const geodataServiceTexts = {
  header: "Geografiska tjänster och funktioner",
  services: [
    {
      title: "Geografisk data",
      description:
        "Vi integrerar Asset Care med geografisk data för er anläggning. Geografisk data kan utgöra allt från positionsbestämd anläggnings- eller verksamhetsinformation till olika typer av bakgrundskartor och satellitbilder. Via kopplingen får ni tillgång till inte bara geografiska positioner utan även viktig attributdata som ger ytterligare information om platser och inventarier. En anläggning med bra geografisk data kan även virtualiseras och utgöra din verksamhets digitala tvilling.",
      alt: "Jordglob ikon",
    },
    {
      title: "Geografisk analys",
      description:
        "Geografiska data används med fördel för att analysera mönster och samband, som exempelvis positionsförändringar, olika typer av ”hot spots”, alltså platser mad avvikande mönster eller anomalier. Tillgången på geografiska indata bestämmer vilka analyser som är möjliga, vi har exempel på hur man kan analysera förändringar på sub millimeternivå för olika tillämpningar.",
      alt: "Slalomräls ikon",
    },
    {
      title: "Geografiskt beslutsstöd",
      description:
        "Integrationen med GIS möjliggör att ge visuell förståelse av komplex geografisk information genom presentation av kartor och diagram, vilket underlättar förståelsen av mönster och trender. Den geografiska datan kan även användas till att bedöma risker och sårbarheter i er anläggning.",
      alt: "Pin ikon",
    },
    {
      title: "Laserdatabearbetning och automatisering",
      description:
        "Ett område med växande antal tillämpningar är analys av laserdata eller LiDAR. Extrahering av positionsbestämd information ur laserdata ger oanade möjligheter inom anläggningsinformation såsom asset/inventariebestämning, 3D-modellering och indata för visualisering och digitala tvillingar. Vi är specialister på informationsförädling genom att automatisera laserbearbetningen för en effektiv process från rådata till färdig produkt.",
      alt: "Rälsar ikon",
    },
  ],
};

export const tdAssetCareTexts = {
  header: "TD Asset Care - för alla era EAM behov",
  intro:
    "TD Asset Care är en komplett 360°-lösning för alla era EAM-behov. Det är en komplett lösning för att göra förvaltning och underhåll av era fysiska tillgångar enkel och effektiv. TD Asset Care innehåller branschspecifika funktioner som förbättrar och lägger till standardunderhållsfunktionalitet. TD Asset Care kan driftas on-prem i era serverhallar, hybrid eller i molnet, allt beroende på vad ni har för behov.",
  buttonText: "Kontakta oss",
  reasonsHeader: "Varför TD Asset Care?",
  reasons: [
    "TD Asset Care hjälper er att fokusera på planering och upprätthållandet av hög och effektiv produktion.",
    "Ni kan lägga mer tid på produktion och mindre på underhåll.",
  ],
  description:
    "TD Asset Care är en förstklassig Enterprise Asset Management-lösning med flera komponenter som innehåller specialanpassade funktioner och moduler för att förbättra och förenkla er verksamhet",
  features: [
    {
      title: "Enterprise Asset Management",
      description:
        "En fullskalig Enterprise Asset Management (EAM) lösning som hjälper er att hantera och optimera era anläggningstillgångar genom hela deras livscykel.",
    },
    {
      title: "Integrerad plattform",
      description:
        "Asset Care erbjuder en integrerad plattform för att övervaka, underhålla och optimera era fysiska tillgångar, såsom utrustning, infrastruktur och anläggningar.",
    },
    {
      title: "Geografiska tjänster och funktioner",
      description:
        "Asset Care innehåller integration med geografiska tjänster som förbättrar analys och visualisering.",
    },
    {
      title: "Industrianpassningar",
      description:
        "Industri- och branschanpassningar för bland annat Järnväg, Pendeltåg, Tunnelbana och fordonsunderhåll.",
    },
    {
      title: "Monitorering och dashboards",
      description:
        "Anpassad monitorering och dashboards för bättre kontroll och översikt.",
    },
    {
      title: "Applikationsförvaltning och support",
      description:
        "Total Digitals-applikationsförvaltning och support enligt ITILs standardprocesser.",
    },
    {
      title: "Livscykelhantering",
      description:
        "Livscykelhantering av hela applikationen för maximal effektivitet och hållbarhet.",
    },
    {
      title: "Språksupport",
      description:
        "Vi erbjuder support och hjälp på ert lokala språk för smidigare kommunikation.",
    },
  ],
  geoservices: [
    {
      title: "Geografisk data",
      description:
        "Vi integrerar Asset Care med geografisk data för er anläggning...",
      alt: "Jordglob ikon",
    },
    {
      title: "Geografisk analys",
      description:
        "Geografiska data används med fördel för att analysera mönster och samband...",
      alt: "Slalomräls ikon",
    },
    {
      title: "Geografiskt beslutsstöd",
      description:
        "Integrationen med GIS möjliggör att ge visuell förståelse av komplex geografisk information...",
      alt: "Pin ikon",
    },
    {
      title: "Laserdatabearbetning och automatisering",
      description:
        "Extrahering av positionsbestämd information ur laserdata...",
      alt: "Rälsar ikon",
    },
  ],
  fordonsunderhall: [
    {
      img: "train",
      alt: "Nytt tåg ikon",
      description:
        "Validering av godkänd bestycking av fordon innan trafiksättning...",
    },
    {
      img: "services02",
      alt: "Document pages with tables on them",
      description:
        "Hjulmåttsregistrering. Funktioner för att kontrollera toleranser och skillnader...",
    },
    {
      img: "services06",
      alt: "Ikon Projekt",
      description:
        "Mätarbaserade underhållsplaner. Anpassade underhållsplaner (FU-mallar) som dynamiskt...",
    },
    {
      img: "validering",
      alt: "Validering ikon",
      description:
        "Underhållsintyg. Utfärdande av intyg för utfört underhållsarbete inklusive signering...",
    },
  ],
};

export const tdTestTexts = {
  TDTest: "TD Test",
  hero: {
    header: "TD Test - Kvalitetssäkra ditt IBM Maximo",
    description:
      "TD Test är det automatiserade testverktyget för både IBM Maximo 7.x och nyaste IBM Maximo Application Suite.",
    description2:
      "Vill ni vara säkra på att era anpassningar fungerar lika bra i senaste versionen av IBM Maximo som i er föregående version är TD Test verktyget för Er!",
    description3:
      "TD Test är till exempel perfekt för att verifiera en lyckad uppgradering till MAS!",
    buttonText: "Kontakta oss",
  },
  about: {
    header: "Vad är TD Test?",
    paragraphs: [
      "TD Test är verktyget för automattester som fungerar lika bra för IBM Maximo 7.x som för nyaste IBM Maximo Application Suite.",
      "Vill ni vara säkra på att era anpassningar fungerar lika bra i senaste versionen av IBM Maximo som i er föregående version är TD Test verktyget för Er!",
      "TD Test är till exempel perfekt för att verifiera en lyckad uppgradering till MAS!",
    ],
  },
  sections: {
    capabilities: {
      header: "Vad kan TD Test?",
      items: [
        {
          title: "Testar Maximo med hjälp av Maximo",
          content: [
            "TD Test pratar via Maximo med både gränssnittet och affärsobjekten (MBO:er)",
            "Maximos gränssnitt testas utan krav på webbläsare eller web-driver",
            "Interna Maximo-funktioner kan testas via affärsobjekten (t.ex. Arbetsorder, FU, Förråd)",
            "Återrapportering och historik av körda test",
            "Hantering av testdata - t.ex. databasisolering som gör att databasen lämnas opåverkad efter test. Samma test och data kan köras om och om. Gammal testdata finns inte kvar och skräpar ner",
            "Logghantering – samlar in logginformation för varje testögonblick för att underlätta eventuell felsökning.",
            "Information kan hämtas från Maximo-, Java- och applikationsserverloggar",
            "Loggutskrifter vid aktuell tidpunkt för körning finns sparad med enkel åtkomst.",
            "Skärmbilder sparas för varje teststeg",
            "Detaljnivån på loggar kan styras per test och påverkar inte Maximos allmänna logginställning.",
          ],
        },
        {
          title: "Heltäckande automattester för Ert Maximo",
          content: [
            "Med TD Test kan du skapa heltäckande tester som verifierar de mest kritiska delarna i ert Maximo",
            "TD Test stöder automattester som kontrollerar:",
            "Maximos användargränssnitt (webb)",
            "Funktioner och egenskaper i Maximos affärsobjekt (MBO)",
            "Integrationer (MIF)",
            "Systemfunktioner",
            "Anpassningar (Java/Automatiseringsscript)",
            "Konfigureringar",
            "Era verksamhetsprocesser i Maximo",
          ],
        },
        {
          title: "Rapportering",
          content: [
            "TD Test har en användarvänlig återrapportering av körda test:",
            "Intuitivt webbgränssnitt",
            "Information visas per teststeg",
            "Tydlig info om vad som gått fel",
            "Aktuellt felmeddelande",
            "Utdrag från logg vid feltidpunkt",
            "Skärmbilder från Maximo sparas vid varje teststeg",
            "Historik och resultat över tidigare körningar",
          ],
        },
        {
          title: "Automatiserade testkörningar",
          content: [
            "Med TD Test kan du enkelt och flexibelt välja hur du vill köra dina test.",
            "Enkelt att köra vid behov som stöd vid utveckling och förändring.",
            "Automattester kan köras via automationsserver (t.ex. Jenkins) men det är inget krav.",
            "Test kan köras enskilda eller grupperade (sviter).",
            "Testkörningar kan schemaläggas.",
            "Automattester kan köras grafiskt via webb eller via konsol.",
          ],
        },
      ],
    },
    features: {
      header: "Funktioner",
      items: [
        {
          title: "Anpassningsbart",
          description:
            "TD Test kan användas för att automattesta i princip vilken applikation som helst, men har framför allt mycket utbyggda funktioner för just IBM Maximo. Ni kan enkelt lägga till egna hjälpfunktioner med anrop direkt mot IBM Maximo för att säkerställa funktionaliteten av era anpassningar och verksamhetsprocesser.",
        },
        {
          title: "Snabbt",
          description:
            "Då våra tester använder IBM Maximos eget ramverk går tester mycket snabbare jämfört med tester som körs via en webbläsare. Vi har mätt upp tester som går upp till 100 gånger snabbare via TD Test.",
        },
        {
          title: "Stabilt",
          description:
            "TD Test kommunicerar direkt med IBM Maximo och inte via webbläsare och har därför inte de problem och brister som testramverk och verktyg som testar via sk webdriver (t.ex. Selenium och Katalon) har. IBM Maximos webbgränssnitt testas i samma stund som det genereras av applikationen, dvs innan det når en webbläsare. Detta gör testen både stabila, pålitliga och snabba.",
        },
        {
          title: "Kostnadseffektivt",
          description:
            "TD Test är enkelt att installera, är resurssnålt och kräver inga egna servrar eller hårdvara. Även utveckling av anpassade automattester för Er verksamhet kommer att göras tidseffektivt eftersom TD Test är lättförståeligt och intuitivt med fördefinierade metoder för kommunikation både mot IBM Maximos användargränssnitt och affärslogik.",
        },
        {
          title: "Användarvänligt",
          description1: " har ett intuitivt gränssnitt med bland annat:",
          description2: "Tydlig återrapportering av körda tester",
          description3: "Uppföljning och översikt av historiska körningar",
          description4: "Exakt återkoppling till var ett test går fel",
          description5: "Loggöversikt och sammandrag",
          description6:
            "Skärmbilder från testkörningar finns sparade för varje teststeg",
          description7: "Funktioner som förenklar hantering av testdata",
        },
        {
          title: "Exempel på test",
          description:
            "Här kan du lägga till en beskrivning av exempeltestet om det behövs.",
        },
      ],
    },
  },
  comparison: {
    header:
      "En jämförelse mellan TD Test och andra sätt att automattesta Maximo",
    columns: [
      "Funktioner",
      "Tester med hjälp av webdriver (t.ex Selenium)",
      "TD Test",
    ],
    features: [
      {
        description:
          "Dynamisk anpassning av test efter gränssnittsförändringar i Maximo",
        webdriver: false,
        tdTest: true,
      },
      {
        description:
          "Klarar att köra samma test mot olika Maximo-versioner utan omskrivning av test",
        webdriver: false,
        tdTest: true,
      },
      {
        description: "Inbyggt stöd för test av Maximo-integrationer",
        webdriver: false,
        tdTest: true,
      },
      {
        description: "Inbyggt stöd för test av Maximos affärslogik (backend)",
        webdriver: false,
        tdTest: true,
      },
      {
        description:
          "Kan köra tester schemalagt utan krav på automatiseringsmotor (t.ex. Jenkins)",
        webdriver: false,
        tdTest: true,
      },
      {
        description:
          "Återrapportering av test med utförlig körningsinformation (t.e.x för felsökning)",
        webdriver: false,
        tdTest: true,
      },
      {
        description: "Tester som inte påverkar systemet/databasen",
        webdriver: false,
        tdTest: true,
      },
      {
        description: "Test av olika webbläsare och webbläsarversioner",
        webdriver: true,
        tdTest: false,
      },
    ],
  },
  kontakt: {
    header: "Kontakt",
    description: "Vill ni veta mer om TD Test? Kontakta oss i dag!",
    form: {
      actionUrl: "https://totaldigital.info/phpmailer/send_email.php",
      fields: {
        name: {
          label: "Namn",
          placeholder: "Skriv ditt namn",
        },
        email: {
          label: "E-post*",
          placeholder: "Skriv din e-post",
        },
        phone: {
          label: "Telefon",
          placeholder: "Skriv ditt telefonnummer",
        },
        company: {
          label: "Företag",
          placeholder: "Skriv ditt företagsnamn",
        },
        message: {
          label: "Meddelande",
          placeholder: "",
        },
        gdprConsenttdtest: {
          label:
            "Jag tillåter Total Digital att lagra och behandla mina personuppgifter och ta emot kommunikation via e-post.",
        },
        submitButton: "Kontakta oss",
      },
    },
  },
  footer: {
    description:
      "TD Test är en del av Total Digitals tjänsteutbud. Oavsett vilken av våra tjänster ni vill ha hjälp med erbjuder vi stöd genom hela processen. För att komma i kontakt med oss kan ni nå oss på följande:",
    contacts: {
      email: "E-post",
      emailLink: "info@totaldigital.se",
      phone: "Telefon",
      phoneNumber: "010 - 10 100 70",
      phoneLink: "tel:0101010070",
      linkedin: "Total Digital på Linkedin",
      linkedinLink:
        "https://www.linkedin.com/company/total-digital-it-solutions/about/",
      website: "Total Digital hemsida",
    },
  },
};
